import React from "react";

export const CreateTicketIcon = () => (
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M26.686 13.186C26.686 20.2882 20.7895 26.0581 13.5 26.0581C6.21054 26.0581 0.313953 20.2882 0.313953 13.186C0.313953 6.08391 6.21054 0.313953 13.5 0.313953C20.7895 0.313953 26.686 6.08391 26.686 13.186Z" stroke="#0E466E" strokeWidth="0.627907"/>
        <path opacity="0.4" d="M19.6224 14.1134C19.1069 14.1134 18.6874 13.6977 18.6874 13.1869C18.6874 12.6755 19.1069 12.2599 19.6224 12.2599C19.7473 12.2599 19.8672 12.2108 19.9552 12.1237C20.0437 12.0359 20.0933 11.9171 20.0933 11.7933L20.0927 10.1122C20.0927 8.69115 18.9254 7.53516 17.4912 7.53516H10.1372C8.70306 7.53516 7.53578 8.69115 7.53578 10.1122L7.53516 11.8474C7.53516 11.9712 7.58476 12.0901 7.6733 12.1778C7.7612 12.2649 7.88113 12.314 8.00609 12.314C8.53918 12.314 8.94104 12.6892 8.94104 13.1869C8.94104 13.6977 8.5216 14.1134 8.00609 14.1134C7.74613 14.1134 7.53516 14.3224 7.53516 14.58V16.2598C7.53516 17.6809 8.70181 18.8375 10.1366 18.8375H17.4919C18.9266 18.8375 20.0933 17.6809 20.0933 16.2598V14.58C20.0933 14.3224 19.8823 14.1134 19.6224 14.1134Z" fill="#0C3F64"/>
        <path d="M15.9689 12.9281L15.2286 13.649L15.4038 14.6681C15.434 14.8445 15.363 15.0184 15.218 15.1227C15.0742 15.2282 14.8864 15.2413 14.7282 15.1572L13.8146 14.6769L12.8991 15.1578C12.8313 15.1936 12.7572 15.2125 12.6837 15.2125C12.5877 15.2125 12.4928 15.1823 12.4112 15.1233C12.2668 15.0184 12.1958 14.8445 12.226 14.6681L12.4005 13.649L11.6602 12.9281C11.5322 12.8038 11.4876 12.6211 11.5428 12.4509C11.5987 12.2814 11.7431 12.1608 11.9196 12.1357L12.9405 11.9869L13.3983 11.0595C13.478 10.9 13.6375 10.8008 13.8146 10.8008H13.8158C13.9935 10.8014 14.153 10.9006 14.2315 11.0601L14.6893 11.9869L15.7121 12.1363C15.8867 12.1608 16.0311 12.2814 16.0864 12.4509C16.1422 12.6211 16.0977 12.8038 15.9689 12.9281Z" fill="#0C3F64"/>
    </svg>
);

export const CreateTeamIcon = () => (
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M26.686 13.186C26.686 20.2882 20.7895 26.0581 13.5 26.0581C6.21055 26.0581 0.313965 20.2882 0.313965 13.186C0.313965 6.08391 6.21055 0.313953 13.5 0.313953C20.7895 0.313953 26.686 6.08391 26.686 13.186Z" stroke="#0E466E" strokeWidth="0.627907"/>
        <g clipPath="url(#clip0_206_5)">
            <path d="M13.4697 15.2285C11.431 15.2285 9.71094 15.5616 9.71094 16.8473C9.71094 18.1335 11.4422 18.4549 13.4697 18.4549C15.5083 18.4549 17.2284 18.1218 17.2284 16.8361C17.2284 15.5499 15.4972 15.2285 13.4697 15.2285Z" fill="#0C3F64"/>
            <path opacity="0.4" d="M13.4678 14.0032C14.8482 14.0032 15.9551 12.89 15.9551 11.5016C15.9551 10.1127 14.8482 9 13.4678 9C12.0873 9 10.9805 10.1127 10.9805 11.5016C10.9805 12.89 12.0873 14.0032 13.4678 14.0032Z" fill="#0C3F64"/>
            <path opacity="0.4" d="M18.8718 12.0846C19.2289 10.6797 18.1818 9.41797 16.8485 9.41797C16.7035 9.41797 16.5649 9.43393 16.4294 9.46107C16.4114 9.46533 16.3913 9.47438 16.3807 9.49034C16.3686 9.51056 16.3776 9.5377 16.3908 9.55526C16.7913 10.1204 17.0215 10.8085 17.0215 11.5471C17.0215 12.2548 16.8104 12.9147 16.44 13.4623C16.4019 13.5187 16.4358 13.5948 16.503 13.6065C16.5961 13.623 16.6913 13.6315 16.7887 13.6342C17.7596 13.6597 18.631 13.0312 18.8718 12.0846Z" fill="#0C3F64"/>
            <path d="M19.8865 15.3907C19.7087 15.0096 19.2796 14.7484 18.6272 14.6201C18.3193 14.5445 17.486 14.4381 16.7108 14.4525C16.6992 14.4541 16.6928 14.4621 16.6918 14.4674C16.6902 14.4748 16.6934 14.4876 16.7087 14.4956C17.0669 14.6739 18.4516 15.4492 18.2775 17.0845C18.2701 17.1553 18.3267 17.2165 18.3971 17.2058C18.7378 17.1569 19.6145 16.9674 19.8865 16.3773C20.0368 16.0654 20.0368 15.703 19.8865 15.3907Z" fill="#0C3F64"/>
            <path opacity="0.4" d="M10.5712 9.46107C10.4363 9.4334 10.2971 9.41797 10.1521 9.41797C8.81882 9.41797 7.77172 10.6797 8.1294 12.0846C8.36961 13.0312 9.24104 13.6597 10.2119 13.6342C10.3093 13.6315 10.4051 13.6225 10.4977 13.6065C10.5648 13.5948 10.5987 13.5187 10.5606 13.4623C10.1902 12.9142 9.97914 12.2548 9.97914 11.5471C9.97914 10.8079 10.2098 10.1199 10.6104 9.55526C10.623 9.5377 10.6326 9.51056 10.6199 9.49034C10.6093 9.47384 10.5897 9.46533 10.5712 9.46107Z" fill="#0C3F64"/>
            <path d="M8.37183 14.6197C7.71945 14.7479 7.29087 15.0092 7.1131 15.3902C6.9623 15.7026 6.9623 16.065 7.1131 16.3774C7.38505 16.967 8.26178 17.157 8.60252 17.2054C8.67289 17.216 8.72897 17.1554 8.72156 17.084C8.54749 15.4493 9.93215 14.6739 10.2909 14.4957C10.3057 14.4872 10.3089 14.4749 10.3073 14.4669C10.3062 14.4616 10.3004 14.4536 10.2888 14.4526C9.5131 14.4377 8.68029 14.5441 8.37183 14.6197Z" fill="#0C3F64"/>
        </g>
        <defs>
            <clipPath id="clip0_206_5">
                <rect width="13" height="10" fill="white" transform="translate(7 9)"/>
            </clipPath>
        </defs>
    </svg>
);

export const CreateUsersIcon = () => (
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M26.686 13.186C26.686 20.2882 20.7895 26.0581 13.5 26.0581C6.21054 26.0581 0.313953 20.2882 0.313953 13.186C0.313953 6.08391 6.21054 0.313953 13.5 0.313953C20.7895 0.313953 26.686 6.08391 26.686 13.186Z" stroke="#0E466E" strokeWidth="0.627907"/>
        <path d="M11.4099 15.1152C9.03144 15.1152 7 15.4826 7 16.9511C7 18.4208 9.01852 18.8008 11.4099 18.8008C13.7883 18.8008 15.8197 18.4334 15.8197 16.9649C15.8197 15.4953 13.8012 15.1152 11.4099 15.1152Z" fill="#0C3F64"/>
        <path opacity="0.4" d="M11.4102 13.7152C13.03 13.7152 14.3282 12.444 14.3282 10.8576C14.3282 9.27127 13.03 8 11.4102 8C9.7909 8 8.49219 9.27127 8.49219 10.8576C8.49219 12.444 9.7909 13.7152 11.4102 13.7152Z" fill="#0C3F64"/>
        <path opacity="0.4" d="M15.5049 10.9106C15.5049 11.7185 15.2571 12.4723 14.8227 13.0984C14.7775 13.1628 14.8174 13.2496 14.8961 13.2634C15.0053 13.2813 15.1174 13.2922 15.2319 13.2945C16.3709 13.3238 17.3931 12.6057 17.6755 11.5241C18.0941 9.9194 16.8658 8.47852 15.3012 8.47852C15.1315 8.47852 14.9689 8.49576 14.8103 8.52739C14.7886 8.53199 14.7651 8.54234 14.7534 8.56074C14.7381 8.58431 14.7493 8.61478 14.7645 8.63491C15.2348 9.2806 15.5049 10.0666 15.5049 10.9106Z" fill="#0C3F64"/>
        <path d="M18.8696 15.3019C18.6612 14.8666 18.1581 14.5682 17.3924 14.4216C17.0314 14.3354 16.0532 14.2129 15.1438 14.2302C15.1303 14.2319 15.1226 14.2411 15.1215 14.2468C15.1197 14.256 15.1238 14.2698 15.1414 14.2796C15.5618 14.4831 17.1864 15.3686 16.982 17.2361C16.9732 17.3178 17.0396 17.3868 17.1224 17.3753C17.5222 17.3195 18.5508 17.1027 18.8696 16.4288C19.0464 16.0724 19.0464 15.6584 18.8696 15.3019Z" fill="#0C3F64"/>
    </svg>
);

export const TicketChannelIcon = () => (
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M26.686 13.186C26.686 20.2882 20.7895 26.0581 13.5 26.0581C6.21055 26.0581 0.313965 20.2882 0.313965 13.186C0.313965 6.08391 6.21055 0.313953 13.5 0.313953C20.7895 0.313953 26.686 6.08391 26.686 13.186Z" stroke="#0E466E" strokeWidth="0.627907"/>
        <path opacity="0.4" d="M8 13.4512C8.03001 14.8547 8.11406 17.2553 8.12606 17.52C8.16868 18.0861 8.38539 18.6581 8.72276 19.0615C9.19219 19.6276 9.77027 19.8803 10.5765 19.8803C11.6906 19.8863 12.9188 19.8863 14.1116 19.8863C15.3092 19.8863 16.4714 19.8863 17.4529 19.8803C18.2477 19.8803 18.8437 19.621 19.3072 19.0615C19.6445 18.6581 19.8612 18.0801 19.8919 17.52C19.9039 17.2973 19.9639 14.6914 19.9999 13.4512H8Z" fill="#0C3F64"/>
        <path d="M13.5508 16.0362V16.8129C13.5508 17.0615 13.7525 17.2632 14.001 17.2632C14.2495 17.2632 14.4512 17.0615 14.4512 16.8129V16.0362C14.4512 15.7876 14.2495 15.5859 14.001 15.5859C13.7525 15.5859 13.5508 15.7876 13.5508 16.0362Z" fill="#0C3F64"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M12.929 15.5373C12.869 15.7552 12.6595 15.8945 12.4326 15.8645C10.9012 15.6508 9.43771 15.1075 8.2023 14.2917C8.07564 14.2089 8 14.0672 8 13.9159V11.8353C8 10.5747 9.02771 9.54936 10.2913 9.54936H11.4721C11.585 8.67773 12.3233 8 13.225 8H14.7749C15.676 8 16.4149 8.67773 16.5278 9.54936H17.7146C18.9746 9.54936 19.9999 10.5747 19.9999 11.8353V13.9159C19.9999 14.0672 19.9237 14.2083 19.7982 14.2917C18.5604 15.1111 17.0909 15.6568 15.5493 15.8699C15.5283 15.8729 15.5079 15.8741 15.4875 15.8741C15.284 15.8741 15.1021 15.7366 15.0511 15.5349C14.9298 15.0571 14.4958 14.7227 13.997 14.7227C13.4915 14.7227 13.0623 15.0499 12.929 15.5373ZM14.775 8.90115H13.225C12.821 8.90115 12.4837 9.17729 12.3828 9.55007H15.6166C15.5158 9.17729 15.1784 8.90115 14.775 8.90115Z" fill="#0C3F64"/>
    </svg>
);

export const AutoSlaIcon = () => (
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M26.686 13.186C26.686 20.2882 20.7895 26.0581 13.5 26.0581C6.21055 26.0581 0.313965 20.2882 0.313965 13.186C0.313965 6.08391 6.21055 0.31395 13.5 0.31395C20.7895 0.31395 26.686 6.08391 26.686 13.186Z" stroke="#0E466E" strokeWidth="0.627907"/>
        <g clipPath="url(#clip0_206_27)">
            <path d="M13.435 14.7779C12.3372 14.7779 11.4492 13.9226 11.4492 12.8484C11.4492 11.7742 12.3372 10.9121 13.435 10.9121C14.5328 10.9121 15.3999 11.7742 15.3999 12.8484C15.3999 13.9226 14.5328 14.7779 13.435 14.7779Z" fill="#0C3F64"/>
            <path opacity="0.4" d="M19.8154 14.4637C19.6825 14.2584 19.4937 14.0532 19.249 13.9232C19.0532 13.8274 18.9273 13.67 18.8154 13.4853C18.4588 12.8968 18.6686 12.1237 19.263 11.7747C19.9622 11.3847 20.1859 10.5158 19.7804 9.83842L19.3119 9.03105C18.9133 8.35368 18.0393 8.11421 17.3471 8.51105C16.7317 8.83947 15.9416 8.62053 15.585 8.03895C15.4731 7.84737 15.4102 7.64211 15.4242 7.43684C15.4451 7.17 15.3612 6.91684 15.2354 6.71158C14.9766 6.28737 14.5082 6 13.9907 6H13.0048C12.4944 6.01368 12.0259 6.28737 11.7672 6.71158C11.6343 6.91684 11.5574 7.17 11.5714 7.43684C11.5854 7.64211 11.5224 7.84737 11.4106 8.03895C11.054 8.62053 10.2638 8.83947 9.65548 8.51105C8.95625 8.11421 8.0892 8.35368 7.68364 9.03105L7.21516 9.83842C6.81659 10.5158 7.04035 11.3847 7.73259 11.7747C8.32694 12.1237 8.53671 12.8968 8.18709 13.4853C8.06822 13.67 7.94236 13.8274 7.74657 13.9232C7.50883 14.0532 7.29906 14.2584 7.18719 14.4637C6.92847 14.8879 6.94245 15.4216 7.20117 15.8663L7.68364 16.6874C7.94236 17.1253 8.42483 17.3989 8.92828 17.3989C9.16602 17.3989 9.44571 17.3305 9.66947 17.1937C9.84428 17.0774 10.0541 17.0363 10.2848 17.0363C10.977 17.0363 11.5574 17.6042 11.5714 18.2816C11.5714 19.0684 12.2147 19.6842 13.0258 19.6842H13.9767C14.7809 19.6842 15.4242 19.0684 15.4242 18.2816C15.4451 17.6042 16.0255 17.0363 16.7177 17.0363C16.9415 17.0363 17.1513 17.0774 17.3331 17.1937C17.5568 17.3305 17.8295 17.3989 18.0743 17.3989C18.5707 17.3989 19.0532 17.1253 19.3119 16.6874L19.8014 15.8663C20.0531 15.4079 20.0741 14.8879 19.8154 14.4637Z" fill="#0C3F64"/>
        </g>
        <defs>
            <clipPath id="clip0_206_27">
                <rect width="13" height="14" fill="white" transform="translate(7 6)"/>
            </clipPath>
        </defs>
    </svg>
);

export const CheckCircleFilledIcon = () => (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.4987 2.08268C18.2435 2.08268 22.9154 6.7556 22.9154 12.4993C22.9154 18.2431 18.2435 22.916 12.4987 22.916C6.75495 22.916 2.08203 18.2431 2.08203 12.4993C2.08203 6.7556 6.75495 2.08268 12.4987 2.08268Z" fill="#51B74F"/>
        <path d="M9 12.373L11.374 14.746L16.12 10" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

export const ArrowRightCircleIcon = () => (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.4987 0.0826797C16.2435 0.0826797 20.9154 4.7556 20.9154 10.4993C20.9154 16.2431 16.2435 20.916 10.4987 20.916C4.75495 20.916 0.0820313 16.2431 0.0820313 10.4993C0.0820313 4.7556 4.75495 0.0826797 10.4987 0.0826797Z" fill="#0074B3"/>
        <path d="M8.99544 6.10195C9.1944 6.10195 9.3944 6.17799 9.54649 6.33008L13.1788 9.94466C13.3257 10.0915 13.4079 10.2905 13.4079 10.4988C13.4079 10.7061 13.3257 10.9051 13.1788 11.052L9.54649 14.6686C9.24128 14.9728 8.74753 14.9728 8.44232 14.6665C8.13815 14.3603 8.13919 13.8655 8.4444 13.5613L11.5204 10.4988L8.4444 7.43633C8.13919 7.13216 8.13815 6.63841 8.44232 6.33216C8.5944 6.17799 8.79544 6.10195 8.99544 6.10195Z" fill="white"/>
    </svg>
);