import React, { Component } from 'react'

export const HelpIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} viewBox="0 0 25 25" fill="none">
            <circle cx="12.5" cy="12.5" r="11.8534" stroke="#C5C7CD" strokeWidth="1.2931" />
            <path d="M11.9896 16.7919H13.3098V18.1719H11.9896V16.7919ZM9.75382 11.4779C9.75382 10.5226 10.0236 9.75522 10.5632 9.17582C11.1028 8.59201 11.8436 8.3001 12.7857 8.3001C13.657 8.3001 14.3536 8.54999 14.8755 9.04977C15.4018 9.54513 15.6649 10.1798 15.6649 10.9538C15.6649 11.4226 15.5676 11.803 15.373 12.0949C15.1829 12.3868 14.7959 12.8158 14.212 13.3819C13.7874 13.7933 13.511 14.1427 13.3828 14.4302C13.2589 14.7132 13.197 15.1334 13.197 15.6907H12.0161C12.0161 15.0582 12.0913 14.5496 12.2417 14.1648C12.392 13.7756 12.7215 13.3311 13.2302 12.8313L13.7609 12.3072C13.9201 12.1568 14.0484 11.9998 14.1457 11.8362C14.3226 11.5487 14.4111 11.2501 14.4111 10.9405C14.4111 10.5071 14.2806 10.1312 14.0196 9.81271C13.7631 9.49427 13.3363 9.33505 12.7392 9.33505C12.0006 9.33505 11.4898 9.60926 11.2067 10.1577C11.0475 10.4629 10.9568 10.9029 10.9347 11.4779H9.75382Z" fill="#2B304D" fillOpacity="0.7" />
        </svg>
    );
}

export const HelpIcon2 = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} viewBox="0 0 25 25" fill="none">
            <circle cx="12.5" cy="12.5" r="11.8534" stroke="#C5C7CD" strokeWidth="1.2931" />
            <path d="M11.9896 16.7919H13.3098V18.1719H11.9896V16.7919ZM9.75382 11.4779C9.75382 10.5226 10.0236 9.75522 10.5632 9.17582C11.1028 8.59201 11.8436 8.3001 12.7857 8.3001C13.657 8.3001 14.3536 8.54999 14.8755 9.04977C15.4018 9.54513 15.6649 10.1798 15.6649 10.9538C15.6649 11.4226 15.5676 11.803 15.373 12.0949C15.1829 12.3868 14.7959 12.8158 14.212 13.3819C13.7874 13.7933 13.511 14.1427 13.3828 14.4302C13.2589 14.7132 13.197 15.1334 13.197 15.6907H12.0161C12.0161 15.0582 12.0913 14.5496 12.2417 14.1648C12.392 13.7756 12.7215 13.3311 13.2302 12.8313L13.7609 12.3072C13.9201 12.1568 14.0484 11.9998 14.1457 11.8362C14.3226 11.5487 14.4111 11.2501 14.4111 10.9405C14.4111 10.5071 14.2806 10.1312 14.0196 9.81271C13.7631 9.49427 13.3363 9.33505 12.7392 9.33505C12.0006 9.33505 11.4898 9.60926 11.2067 10.1577C11.0475 10.4629 10.9568 10.9029 10.9347 11.4779H9.75382Z" fill="#2B304D" fillOpacity="0.7" />
        </svg>
    );
}

export const DowncaretIcon = () => {
    return (
      <svg
        width="10"
        height="6"
        viewBox="0 0 10 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.5 1.25L5 4.75L1.5 1.25"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  export const PlusIcon = () => {
    return (
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.19486 6.95428L4.79963 7.02689"
          stroke="currentColor"
          strokeWidth="1.21622"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.03707 9.19234L6.96484 4.79297"
          stroke="currentColor"
          strokeWidth="1.21622"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.81124 1H4.18811C2.22854 1 1 2.38746 1 4.35092V9.64908C1 11.6125 2.2227 13 4.18811 13H9.81059C11.7766 13 13 11.6125 13 9.64908V4.35092C13 2.38746 11.7766 1 9.81124 1Z"
          stroke="currentColor"
          strokeWidth="1.21622"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }