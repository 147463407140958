// @ts-nocheck
import React, {useState, Fragment, useEffect, useRef} from 'react';
import axios from "axios";
// react bootstrap components
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
// 
import {getUserInitials} from '../../../../helper';
import { getCustomers } from 'reduxstore/actions/customerActions';
// 
import { NotificationManager } from 'react-notifications';
import ScaleLoader from 'react-spinners/ScaleLoader';
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import { httpOnpremGet } from "../../../../helpers/httpMethodsOnprem";


const Profile = (currentCustomer, customerLoaded) => {

    const [detailsLoaded, setDetailsLoaded] = useState(false);
    const [customerDetails, setCustomerDetails] = useState({...currentCustomer});

    // SPECIMEN
    const override = css``;
    const [sharedolderSpecimen, setSharedolderSpecimen] = useState(null)
    const [loadingSpecimen, setLoadingSpecimen] = useState(false)
    const [disableSpecimenBtn, setDisableSpecimenBtn] = useState(!customerDetails?.custom_fields?.accountNumber)
    const elemRef = useRef()



    useEffect(() => {
        if(customerLoaded){
            getCustomerDetails(customerDetails?.custom_fields?.accountNumber, customerDetails?.custom_fields?.registerID);
        }
    }, [customerLoaded]);

    useEffect(() => {
        if(sharedolderSpecimen){
            elemRef.current.scrollIntoView({
                behavior: "smooth",
                block: "start"
            });
        }        
    }, [sharedolderSpecimen])

    //
    const getCustomerDetails = async (accountNumber, registerID) =>{
        const res = await httpOnpremGet(`getHolderKYC?accountNumber=${accountNumber}&registerID=${registerID}`);
        if (res.status === 200) {
            setCustomerDetails((prevState) => ({
                ...prevState,
                ...res.data[0],
            }));
            setDetailsLoaded(true);
        } else {
            return NotificationManager.error("Could not retrieve user data...", "Error", 4000);
        }
    }
    // 
    const initialsFromString = (...val) =>{
        let valArr = val.filter(i => i).join(' ').split(' ');
        let firstStrCapitalized = valArr[0].toUpperCase().charAt(0);
        let secondStrCapitalized = "";
        if(valArr.length > 1){
            secondStrCapitalized = valArr[1].toUpperCase().charAt(0);
        }
        return `${firstStrCapitalized}${secondStrCapitalized}`;
    }


    const getSharedolderSpecimen = async (accountNumber, registerID) =>{
        const res = await httpOnpremGet(`getHolderDoc?accountNumber=${accountNumber}&registerID=${registerID}`);
        if (res.status === 200 && res.data[0].HolderDoc) {            
            
            setSharedolderSpecimen(res.data)

        } else {
            NotificationManager.error(res.data[0].ErrorMessage, "Error", 4000);
        }
        
        setLoadingSpecimen(false);
    }

    const showSpecimen = (e) => {
        e.preventDefault()
        setLoadingSpecimen(true);
        setDisableSpecimenBtn(true)
        getSharedolderSpecimen(customerDetails?.custom_fields?.accountNumber, customerDetails?.custom_fields?.registerID);
    }


    return (
        <Fragment>
            {!detailsLoaded
                ? <div className="single-cust-loader">
                    <ScaleLoader loading={true} color={"#006298"}/>
                </div>
                :
                <div className="">
                    <Container fluid className="px-5">
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <h6 className="text-muted acx-ls-30 acx-fs-12">CONTACT INFORMATION</h6>
                        </div>
                        {/* CONTACT INFORMATION< */}
                        <Row className="mb-1">
                            <Col md={8}>
                                <Row className="justify-content-between mb-3">
                                    <Col md={4}>
                                        <div className="d-inline-flex flex-column flex-grow-0">
                                            <small className="text-muted small mb-2">First Name</small>
                                            <h6 className="text-dark border-bottom" >
                                                {customerDetails?.firstName || customerDetails?.firstname || customerDetails?.FirstName || ""}
                                            </h6>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="d-inline-flex flex-column flex-grow-0">
                                            <small className="text-muted small mb-2">Last Name</small>
                                            <h6 className="text-dark border-bottom" >
                                                {customerDetails?.LastName || customerDetails?.lastName || customerDetails?.lastname || ""}
                                            </h6>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="d-inline-flex flex-column flex-grow-0">
                                            <small className="text-muted small mb-2">Middle Name</small>
                                            <h6 className="text-dark border-bottom" >
                                                {customerDetails?.MiddleName || customerDetails?.middleName || customerDetails?.middlename || ""}
                                            </h6>
                                        </div>
                                    </Col>
                                </Row>
                                
                                <Row className="justify-content-between mb-3">
                                    <Col md={4}>
                                        <div className="d-inline-flex flex-column flex-grow-0">
                                            <small className="text-muted small mb-2">Title</small>
                                            <h6 className="text-dark border-bottom" >
                                                {customerDetails?.Title || ""}
                                            </h6>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="d-inline-flex flex-column flex-grow-0">
                                            <small className="text-muted small mb-2">Gender</small>
                                            <h6 className="text-dark border-bottom" >
                                            {(customerDetails?.Sex === "M")? "Male" : (customerDetails?.Sex === "F")? "Female" : ""}
                                            </h6>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="d-inline-flex flex-column flex-grow-0">
                                            <small className="text-muted small mb-2">Birthday</small>
                                            <h6 className="text-dark border-bottom" >
                                                {""}
                                            </h6>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="justify-content-between mb-3">
                                    <Col md={4}>
                                        <div className="mb-3">
                                            <div className="d-inline-flex flex-column flex-grow-0">
                                                <small className="text-muted small mb-2">Phone</small>
                                                <h6 className="text-dark border-bottom">
                                                    {customerDetails?.Phone || ""}
                                                </h6>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="mb-3">
                                            <div className="d-inline-flex flex-column flex-grow-0">
                                                <small className="text-muted small mb-2">Alt Phone</small>
                                                <h6 className="text-dark border-bottom">
                                                    {customerDetails?.AltPhone || ""}
                                                </h6>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="mb-3">
                                            <div className="d-inline-flex flex-column flex-grow-0">
                                                <small className="text-muted small mb-2">Email</small>
                                                <h6 className="text-dark border-bottom">
                                                    {customerDetails?.Email || ""}
                                                </h6>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={4}>
                                <div className="text-center">
                                    <div className="user-initials blue mx-auto d-flex justify-content-center align-items-center avatar avatar-xxl rounded-3">
                                        <h1 className="mb-0">
                                            {
                                            initialsFromString(
                                                customerDetails?.firstName || customerDetails?.firstname || customerDetails?.FirstName || "", 
                                                customerDetails?.LastName || customerDetails?.lastName || customerDetails?.lastname || "", 
                                                customerDetails?.MiddleName || customerDetails?.middleName || customerDetails?.middlename)}
                                        </h1>


                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <hr className="mb-3"/>
                        <h6 className="text-muted mb-4 acx-ls-30 acx-fs-12">ADDRESS 1</h6>
                        <Row className="mb-1">
                            <Col md={8}>
                                <Row className="mb-3">
                                    <Col md={6}>
                                        <div className="d-inline-flex flex-column flex-grow-0 mb-3">
                                            <small className="text-muted small mb-2">Address</small>
                                            <h6 className="text-dark border-bottom" >
                                                {customerDetails?.Address1 || ""}
                                            </h6>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="d-inline-flex flex-column flex-grow-0 mb-3">
                                            <small className="text-muted small mb-2">Address 2</small>
                                            <h6 className="text-dark border-bottom" >
                                                {customerDetails?.Address2 || ""}
                                            </h6>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="d-inline-flex flex-column flex-grow-0 mb-3">
                                            <small className="text-muted small mb-2">City/Town</small>
                                            <h6 className="text-dark border-bottom" >
                                                {customerDetails?.CityTown || ""}
                                            </h6>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="d-inline-flex flex-column flex-grow-0 mb-3">
                                            <small className="text-muted small mb-2">Residence State Id</small>
                                            <h6 className="text-dark border-bottom" >
                                                {customerDetails?.ResidenceStateId || ""}
                                            </h6>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <hr className="mb-3"/>
                        <h6 className="text-muted mb-4 acx-ls-30 acx-fs-12">Account Details</h6>
                        <Row className="mb-1">
                            <Col md={8}>
                                <Row className="justify-content-between mb-3">
                                    <Col md={4}>
                                        <div className="d-inline-flex flex-column flex-grow-0 mb-3">
                                            <small className="text-muted small mb-2">Residence State Id</small>
                                            <h6 className="text-dark border-bottom" >
                                                {customerDetails?.ResidenceStateId || ""}
                                            </h6>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="d-inline-flex flex-column flex-grow-0 mb-3">
                                            <small className="text-muted small mb-2">Account Number</small>
                                            <h6 className="text-dark border-bottom" >
                                                {customerDetails?.BankAccountNumber || ""}
                                            </h6>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="d-inline-flex flex-column flex-grow-0 mb-3">
                                            <small className="text-muted small mb-2">Bank Bvn</small>
                                            <h6 className="text-dark border-bottom" >
                                                {customerDetails?.BankBv || ""}
                                            </h6>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="d-inline-flex flex-column flex-grow-0 mb-3">
                                            <small className="text-muted small mb-2">Sort Code</small>
                                            <h6 className="text-dark border-bottom" >
                                                {customerDetails?.SortCode || ""}
                                            </h6>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="d-inline-flex flex-column flex-grow-0 mb-3">
                                            <small className="text-muted small mb-2">Origin State Id</small>
                                            <h6 className="text-dark border-bottom" >
                                                {customerDetails?.OriginStateId || ""}
                                            </h6>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="d-inline-flex flex-column flex-grow-0 mb-3">
                                            <small className="text-muted small mb-2">Occupation Id</small>
                                            <h6 className="text-dark border-bottom" >
                                                {customerDetails?.OccupationId || ""}
                                            </h6>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="d-inline-flex flex-column flex-grow-0 mb-3">
                                            <small className="text-muted small mb-2">No Tax</small>
                                            <h6 className="text-dark border-bottom" >
                                                {customerDetails?.NoTax || ""}
                                            </h6>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="d-inline-flex flex-column flex-grow-0 mb-3">
                                            <small className="text-muted small mb-2">Holder Type Id</small>
                                            <h6 className="text-dark border-bottom" >
                                                {customerDetails?.HolderTypeId || ""}
                                            </h6>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="d-inline-flex flex-column flex-grow-0 mb-3">
                                            <small className="text-muted small mb-2">Next of Kin</small>
                                            <h6 className="text-dark border-bottom" >
                                                {customerDetails?.NxKin || ""}
                                            </h6>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="d-inline-flex flex-column flex-grow-0 mb-3">
                                            <small className="text-muted small mb-2">Tin</small>
                                            <h6 className="text-dark border-bottom" >
                                                {customerDetails?.Tin || ""}
                                            </h6>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="d-inline-flex flex-column flex-grow-0 mb-3">
                                            <small className="text-muted small mb-2">NIN</small>
                                            <h6 className="text-dark border-bottom" >
                                                {customerDetails?.NIN || ""}
                                            </h6>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="d-inline-flex flex-column flex-grow-0 mb-3">
                                            <small className="text-muted small mb-2">Old Account Number</small>
                                            <h6 className="text-dark border-bottom" >
                                                {customerDetails?.OldAccountNumber || ""}
                                            </h6>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>


                        { sharedolderSpecimen && sharedolderSpecimen[0] ?
                            <div>
                                <h6 className="acx-fs-12 acx-ls-30 fw-bold bg-at-blue-lighter p-2 mb-3">SIGNATURE</h6>
                                <img ref={elemRef} src={`data:image/png;base64,${sharedolderSpecimen[0]?.HolderDoc}`} alt="Sharedolder Specimen" />
                            </div>

                            : ""
                        }

                        { sharedolderSpecimen && sharedolderSpecimen[1] ?
                            <div>
                                <h6 className="acx-fs-12 acx-ls-30 fw-bold bg-at-blue-lighter p-2 mb-3">SPECIMENs</h6>
                                <img ref={elemRef} src={`data:image/png;base64,${sharedolderSpecimen[1]?.HolderDoc}`} alt="Sharedolder Specimen" />
                            </div>

                            : ""
                        } 

                        <button className="d-flex btn acx-btn-primary btn-sm my-md-3 pt-1" disabled={disableSpecimenBtn} onClick={showSpecimen}>
                                <span>Show Sharedolder Specimen</span>

                                { loadingSpecimen &&
                                    <div className="ps-2">
                                        <ClipLoader color="#ffffff" loading={loadingSpecimen} css={override} size={20} />
                                    </div>
                                }
                                
                        </button>


                    </Container>
                </div>
            }
        </Fragment>
    );
};

export default Profile;
// export default Profile