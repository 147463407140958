import React from "react";

const Responce = () => {
  return (
    <div className="response">
      <input type="checkbox" />
      <p>Your request has been received</p>
      <button>edit</button>
      <button>Delete</button>
    </div>
  );
};

export default Responce;
