import React, {useState, Fragment, useEffect} from 'react';
import MessageIcon from '../../../assets/svgicons/Message.svg';
import TicketIcon from '../../../assets/svgicons/Ticket.svg';
import ShowIcon from '../../../assets/svgicons/Show.svg';
import WorkIcon from '../../../assets/svgicons/Work.svg';
import CallIcon from '../../../assets/svgicons/Call.svg';
import LocationIcon from '../../../assets/svgicons/Location.svg';
import ProfileLightIcon from '../../../assets/svgicons/Profile-Light.svg';
import DiscountIcon from '../../../assets/svgicons/Discount.svg';
import ImageDefault from '../../../assets/svgicons/image-default.svg';
import {Tabs, Tab, Button} from 'react-bootstrap';
import '../../../styles/Customer.css';
import {Link} from 'react-router-dom';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ScaleLoader from 'react-spinners/ScaleLoader';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import {getCurrentCustomer} from '../../../reduxstore/actions/customerActions';
import {getUserInitials} from '../../../helper';
import TicketHistory from './components/TicketHistory';
import Profile from './components/Profile';
import Notes from './components/Notes';
import Timeline from './components/Timeline';
import CreateCustomerModal from './CreateCustomerModal';

const CircleIcon = (props) => <span style={{ backgroundColor: props.color }} className="cust-grey-circle"><img src={props.icon} alt="" className="pe-none"/></span>;

const Customer = ({isCustomerLoaded, getCurrentCustomer, isCurrentCustomerLoaded, currentCustomer}) => {

    const [createModalShow, setCreateModalShow] = useState(false);
    const {id} = useParams();
    const [tabKey, setTabKey] = useState('ticket-history');
    const [showUpdate, setShowUpdate] = useState(false);

    useEffect(() => {
        getCurrentCustomer(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCustomerLoaded])

    const getStatusColor = status => {
        let output;
        switch (status.toLowerCase()) {
            case 'pending':
                output = 'yellow';
                break;
            case 'overdue':
                output = 'red';
                break;
            case 'closed':
                output = 'green';
                break;
            default:
        }
        return output;
    };

    const tagColor = [
        { item: ['a', 'b', 'c'], color: "#662D91", background: "#F8EEFF" },
        { item: ['d', 'e', 'f'], color: "#F40D0D", background: "#FFEAEA" },
        { item: ['g', 'h', 'i'], color: "#662D91", background: "#F8EEFF" },
        { item: ['j', 'k', 'w'], color: "#1E90FF", background: "#E3F1FF" },
        { item: ['l', 'm', 'n'], color: "#662D91", background: "#F8EEFF" },
        { item: ['o', 'p'], color: "#1E90FF", background: "#E3F1FF" },
        { item: ['q', 'r', 's'], color: "#F40D0D", background: "#FFEAEA" },
        { item: ['t', 'u', 'v'], color: "#662D91", background: "#F8EEFF" },
        { item: ['x', 'y', 'z'], color: "#1E90FF", background: "#E3F1FF" },
      ];

    function handleEditClick () {
        setCreateModalShow(true);
    }

    return (
        <Fragment>
            {!isCurrentCustomerLoaded
                ?   <div className="single-cust-loader">
                        <ScaleLoader loading={true} color={"#006298"}/>
                    </div>
                : !currentCustomer ? <div>No Customer Found.</div> : <div className="pb-4"> <div
                    style={{
                    border: '1px solid #f1f1f1'
                }}
                    className="">

                    <div
                        style={{
                        overflowX: "hidden"
                    }}
                        className="bg-secondary pt-0 bg-white">

                        <div style={{margin: "0 -0.5rem"}}
                            className="px-5 py-3 d-flex justify-content-between">
                            <div>
                                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                    <li className="nav-item me-2" role="presentation">
                                        <button
                                            className={`nav-link ${tabKey === 'profile' && 'nav-active'} text-muted ps-1 pe-3`}
                                            id="pills-profile-tab"
                                            type="button"
                                            onClick={() => setTabKey('profile')}>Profile</button>
                                    </li>
                                    <li className="nav-item mx-3" role="presentation">
                                        <button
                                            className={`nav-link ${tabKey === 'ticket-history' && 'nav-active'} text-muted px-3`}
                                            id="pills-profile-tab"
                                            type="button"
                                            onClick={() => setTabKey('ticket-history')}>Ticket History</button>
                                    </li>
                                    {/* <li className="nav-item ms-2" role="presentation">
                                        <button
                                            className={`nav-link ${tabKey === 'notes' && 'nav-active'} text-muted px-3`}
                                            id="pills-notes-tab"
                                            type="button"
                                            onClick={() => setTabKey('notes')}>Notes</button>
                                    </li> */}
                                </ul>
                            </div>
                            <div className="d-flex align-items-md-center">
                                <div>
                                    {/* <button
                                        type="button"
                                        className="btn btn-sm btn-outline-secondary px-md-3 mx-md-2 me-1">
                                        <img src={TicketIcon} className="pe-none" alt=""/>&nbsp; New Ticket
                                    </button> */}
                                    <button
                                        type="button"
                                        className="reset-btn-outline btn btn-sm border btn-outline-secondary px-md-2 mx-md-2"><img src={MessageIcon} className="pe-none" alt=""/>&nbsp; Activation Email
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div>
                            {/* Ticket History Tab */}
                            <Tabs
                                id="controlled-tab-example"
                                activeKey={tabKey}
                                onSelect={(k) => setTabKey(k)}
                                className="mb-3">
                                <Tab eventKey="profile" title="">
                                    {!isCustomerLoaded? 
                                        <Profile {...currentCustomer} customerLoaded={isCustomerLoaded} /> 
                                        : 
                                        <p className="text-center">Getting Details</p>
                                    }
                                </Tab>

                                <Tab eventKey="ticket-history" title="">
                                    <TicketHistory currentCustomerId={id} />
                                </Tab>

                                {/* Notes Tab */}
                                {/* <Tab eventKey="notes" className="">
                                    <Notes />
                                </Tab> */}

                                {/* Timeline tab */}
                                {/* <Tab eventKey="timeline" className="px-2" title="timeline">
                                    <Timeline />
                                </Tab> */}
                            </Tabs>
                        </div>

                    </div>

                </div>
            </div>}

            {/* Profile Update OffCanvas */}
            <div
                className={showUpdate
                ? "update-backdrop"
                : ''}
                onClick={() => setShowUpdate(false)}></div>
            <div
                className="offcanvas offcanvas-end show"
                tabIndex={-1}
                id="uploadSidebar"
                aria-labelledby="offcanvasRightLabel"
                style={{
                visibility: `${showUpdate
                    ? 'visible'
                    : 'hidden'}`
            }}>
                <div className="offcanvas-header mx-2 mt-3">
                    <h5 id="offcanvasRightLabel" className="mt-1">Update Profile</h5>
                    <div
                        className="d-flex justify-content-center align-items-center update-close-wrap">
                        <button
                            type="button"
                            className="btn-close text-reset p-0 m-0 h-100 w-100"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                            onClick={() => setShowUpdate(false)}>x</button>
                    </div>
                </div>
                <div className="offcanvas-body">
                    <form className="px-2">
                        <div className="mb-3">
                            <div className="d-flex">
                                <div
                                    className="col-auto rounded-3 d-flex justify-content-between align-items-center me-5">
                                    <div className="ms-3 d-flex justify-content-between align-items-center">
                                        <img src={ImageDefault} alt="" className="pe-none"/>
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="imageInput" className="form-label btn bg-at-blue-light hover-op-8">Upload Photo</label>
                                    <p className="op-9">
                                        <small>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</small>
                                    </p>
                                </div>
                            </div>
                            <input type="file" className="form-control" id="imageInput"/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="firstNameInput" className="form-label">First Name</label>
                            <input type="text" className="form-control" id="firstNameInput"/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="lastNameInput" className="form-label">Last Name</label>
                            <input type="text" className="form-control" id="lastNameInput"/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="emailInput" className="form-label">Email address</label>
                            <input type="email" className="form-control" id="emailInput"/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="phoneNumerInput" className="form-label">Phone Number</label>
                            <input type="tel" className="form-control" id="phoneNumberInput"/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="companyInput" className="form-label">Company</label>
                            <input type="text" className="form-control" id="companyInput"/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="addressInput" className="form-label">Address</label>
                            <textarea className="form-control" id="addressInput"></textarea>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="customerGroupInput" className="form-label">Customer Group</label>
                            <input type="text" className="form-control" id="customerGroupInput"/>
                        </div>
                        <button
                            type="submit"
                            className="btn bg-at-blue-light rounded-0 d-block w-100 mt-5 hover-op-8">Update</button>
                    </form>
                </div>
            </div>
            {/* <!-- end of profile update canvas --> */}

            <CreateCustomerModal createModalShow={createModalShow} setCreateModalShow={setCreateModalShow} isEditing={true} customerId={id} fromCustDetails={true} custId={id} />

        </Fragment>
    )
}

const mapStateToProps = (state, ownProps) => ({customers: state.customer.customers, isCustomerLoaded: state.customer.isCustomerLoaded, isCurrentCustomerLoaded: state.customer.isCurrentCustomerLoaded, currentCustomer: state.customer.currentCustomer});

export default connect(mapStateToProps, {getCurrentCustomer})(Customer);